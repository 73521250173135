import React, { createContext } from "react";

import useOverlay from "../hooks/useOverlay";

export const ModalContext = createContext();

const OverlayContext = (props) => {
  const { Modal, setModal } = useOverlay();

  const ToggleModalOn = () => setModal({ ...Modal, show: true });

  const ToggleModalOff = () => setModal({ ...Modal, show: false });

  const OpenModal = (value) => setModal({ show: true, current: value });

  const global = { Modal, ToggleModalOn, ToggleModalOff, OpenModal };

  return (
    <ModalContext.Provider value={{ ...global }}>
      {props.children}
    </ModalContext.Provider>
  );
};
export default OverlayContext;
