import bell from "../assets/bell.png";
import styles from "../css/userboard.module.css";

const UserBoard = ({ User }) => {
  const imgUrl = `${process.env.REACT_APP_IMG_URL}${User.avatar}`;

  function copyToClipboard() {
    let firstline = `Hey! Sign up for Retala using my refferal code ${User.unique_code}.`;
    let nextline = " \nUse this code to register at retala.app";
    let text = `${firstline}${nextline}`;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        window.alert(text);
      })
      .catch(() => {
        window.alert("something went wrong");
      });
  }

  return (
    <>
      <article className={styles.wallet}>
        <div className={styles.top}>
          <img src={imgUrl} alt="gfhf" />
          <p>{User?.fullname}</p>
          <img src={bell} alt="tedg" />
        </div>
        <aside>
          <h4>Wallet</h4>
          <p className={styles.amount}>
            N{User?.wallet_amt && User?.wallet_amt.toLocaleString()}.00
          </p>
          <a href="#wallet">
            Withdraw Funds <i className="bi bi-chevron-right"></i>
          </a>
          <div>
            <p>Affiliate Code</p>
            <h2 onClick={copyToClipboard} className="cursor-pointer">
              {User?.unique_code}
            </h2>
            <p className={`${styles.copy} cusor-pointer`}>
              <i
                onClick={copyToClipboard}
                className="bx bxs-copy bx-sm  pr-1"
              ></i>
              Click to copy
            </p>
          </div>
          <button>Share on Facebook</button>
          <br />
          <button>Share on Twitter</button>
        </aside>
      </article>
    </>
  );
};

export default UserBoard;
