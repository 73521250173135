import { useState, useContext } from "react";
import axios from "axios";

import data from "../banks.json";

import { ModalContext } from "../../context/overlaycontext";

const MobileBankDetails = ({ User }) => {
  const { ToggleModalOff } = useContext(ModalContext);

  let initial = {
    bank: User.bank,
    acct_name: User.acct_name,
    acct_num: User.acct_num,
  };

  const [Input, setInput] = useState(initial);
  const [Loading, setLoading] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setInput({ ...Input, [name]: value });
  }

  const handleUpdate = (e) => {
    e.preventDefault();

    let values = {};

    if (Input.bank !== User.bank) {
      values.bank = Input.bank;
    }
    if (Input.acct_name !== User.acct_name) {
      values.acct_name = Input.acct_name;
    }

    if (Input.acct_num !== User.acct_num) {
      values.acct_num = Input.acct_num;
    }

    if (values.length === 0) {
      return null;
    } else {
      setLoading(true);
      axios
        .patch(process.env.REACT_APP_USER_URL + User._id, { values })
        .then(() => {
          setLoading(false);
          window.location.reload();
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
    // console.log(values);
    // setInput(initial);
    // setUState("Details Updated successfully");
  };

  return (
    <section className="overlay-mobile ">
      <form onSubmit={handleUpdate} encType="multipart/form-data">
        <span>
          <h4>Update Bank Details</h4>{" "}
          <i
            onClick={ToggleModalOff}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>
        <hr />
        <section className="flex flex-col items-center">
          <div>
            <label> Bank Name </label>
            <select name="bank" value={Input.bank} onChange={handleChange}>
              <option value={User.bank}>{User.bank}</option>
              {data.map((bank) => (
                <option key={bank.id} value={bank.name}>
                  {bank.name}
                </option>
              ))}
            </select>

            {/* <input type="email" value={Input.bank} onChange={handleChange} /> */}

            <label> Account Number </label>
            <input
              name="acct_num"
              type="text"
              value={Input.acct_num}
              onChange={handleChange}
              minLength="10"
            />

            <label> Account Name </label>
            <input
              name="acct_name"
              type="text"
              value={Input.acct_name}
              onChange={handleChange}
            />
          </div>

          <button className="btn-1">
            {Loading ? "Processing" : "Update Details"}
          </button>
        </section>
      </form>
    </section>
  );
};

export default MobileBankDetails;
