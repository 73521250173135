import check from "../assets/check.png";
import styles from "../css/listItem.module.css";

const ListItem = () => {
  return (
    <>
      <aside className={styles.aside}>
        <p className={styles.name}>Jane Adesoya</p>
        <p className={styles.detail}>activated Pro plan using your code</p>
        <p className={styles.date}>3 days ago</p>
        <p className={styles.profit}>+750%</p>
        <img src={check} alt="" />
        <i className="bi bi-chevron-down"></i>
      </aside>
    </>
  );
};

export default ListItem;
