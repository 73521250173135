import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../../css/mobile.module.css";

import { UserContext } from "../../context/marketercontext";
import { ModalContext } from "../../context/overlaycontext";

const Settings = () => {
  const { User, handleLogout, Loading } = useContext(UserContext);
  const { OpenModal } = useContext(ModalContext);

  const [Logout, setLogout] = useState(false);

  return (
    <>
      {Loading && <div className="loader"></div>}
      {User && (
        <main className={`${styles.settings} pt-6 px-4`}>
          <Link to={`/account/dashboard&mobile`}>
            <i className="bx bx-arrow-back bx-sm"></i>
          </Link>
          <h2 className="flex items-center gap-x-2 py-4 text-18 font-semibold">
            <i className="bx bx-cog bx-sm"></i> Settings
          </h2>
          <div className={styles.acct}>
            <h2>
              <i className="bx bx-user bx-sm"></i> Account
            </h2>
            <p>
              Change Password <i className="bi bi-chevron-right"></i>
            </p>
            <p onClick={() => OpenModal(7)}>
              Update payment details <i className="bi bi-chevron-right"></i>
            </p>
            <p>
              Privacy policy <i className="bi bi-chevron-right"></i>
            </p>
          </div>

          <div className={styles.acct}>
            <h2>
              <i className="bx bx-bell bx-sm"></i> Notifications
            </h2>
            <p>Email/SMS notifications</p>
            <p>Account activity</p>
          </div>
          <button
            onClick={() => {
              setLogout(true);
              handleLogout();
            }}
            className={styles.logout}
          >
            {Logout ? "logging out..." : "logout"}
          </button>
        </main>
      )}
    </>
  );
};

export default Settings;
