import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import retala from "../../dist/retala.png";
import menu from "../../dist/menu.png";

const NavBar = ({ setMenu }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    let current = window.location.pathname;
    if (current === "/") {
      setContent("lg:flex");
    }
  }, []);

  const showMenu = () => setMenu(true);

  return (
    <nav className="landing-navbar py-3 lg:py-4 flex items-center justify-between px-7 lg:px-28">
      <a href="/">
        <img className="" src={retala} alt="retala logo" />
      </a>{" "}
      <ul
        className={`hidden ${content} items-center justify-between font-semibold`}
      >
        <Link to="/" className="cursor-pointer mx-5">
          Home
        </Link>
        <a href="/account/login" className="cursor-pointer mx-5">
          Login
        </a>
        <a href="#features" className="cursor-pointer mx-5">
          Features
        </a>
        <a
          href="/account/signup"
          className="py-1 px-9 mx-5 rounded bg-primary text-white cursor-pointer "
        >
          Register
        </a>
      </ul>
      <img className="lg:hidden" src={menu} alt="menu" onClick={showMenu} />
    </nav>
  );
};

export default NavBar;
