import { useContext } from "react";
import MobileDashboard from "../pages/mobile/dashboard";

import { UserContext } from "../context/marketercontext";

const MobileAuth = () => {
  const { User, Referral } = useContext(UserContext);

  const renderCurrentPage = (login) => {
    if (login) {
      return <MobileDashboard User={User} Referral={Referral} />;
    } else if (login === false) {
      return window.location.replace("/account/login");
    } else {
      return <div className="loader"></div>;
    }
  };

  return <>{User && renderCurrentPage(User.login)}</>;
};

export default MobileAuth;
