import Header from "./landing.components/header";
import Footer from "./landing.components/footer";
import Points from "./landing.components/points";
import Features from "./landing.components/features";

const LandingPage = () => {
  return (
    <main className="landingPages text-primary">
      <section
        id="home"
        className="mt-11 lg:mt-auto flex flex-col items-center lg:h-customScreen"
      >
        <Header />
      </section>
      <section id="features">
        <Features />
      </section>
      <section id="points">
        <Points />
      </section>
      <section>
        <Footer />
      </section>
    </main>
  );
};

export default LandingPage;
