import { Link } from "react-router-dom";
import ListItem from "../components/listItem";
import UserBoard from "../components/userboard";
import styles from "../css/dashboard.module.css";

const Dashboard = ({ User, Referral, handleLogout }) => {
  return (
    <>
      <main className={styles.dashboard}>
        <aside className={styles.navigation}>
          <Link className={styles.active} to={`/account/dashboard`}>
            <i title="Home" className="bx bxs-home-alt-2 bx-md"></i>
          </Link>
          <Link to={`/account/profile`}>
            <i title="account" className="bx bxs-user-circle bx-md"></i>
          </Link>
          <Link onClick={handleLogout} className={styles.logout} to="#">
            <i title="logout" className="bx bx-power-off bx-md"></i>
          </Link>
        </aside>

        <section className={styles.view}>
          <h2>My Dashboard</h2>
          <div className={styles.grid}>
            <aside className={styles.boxes}>
              <p className={styles.title}>Total Referrals</p>
              <i className="bx bxs-user bx-lg"></i>
              <img src="" alt="" />
              <p className={styles.number}>0</p>
              <button>
                View All <i className="bi bi-chevron-right"></i>
              </button>
            </aside>
            <aside className={styles.boxes}>
              <p className={styles.title}>Paid Referrals</p>
              <i className="bx bxs-check-circle bx-lg"></i>
              <img src="" alt="" />
              <p className={styles.number}>0</p>
              <button>
                View All <i className="bi bi-chevron-right"></i>
              </button>
            </aside>
            <aside className={styles.boxes}>
              <p className={styles.title}>This Month</p>
              <i className="bx bx-money bx-lg"></i>
              <img src="" alt="" />
              <p className={styles.number}>+N0.00</p>
              <button>
                View All <i className="bi bi-chevron-right"></i>
              </button>
            </aside>
          </div>

          <div className={styles.list}>
            <span>
              <h2>Overview</h2>
              <a href="#forward">
                More Information <i className="bi bi-chevron-right"></i>
              </a>
            </span>
            <section className="listItem">
              {Referral.length === 0 ? (
                <div className="text-center leading-10 my-20">
                  No Referral Rercords
                </div>
              ) : (
                <ListItem />
              )}
            </section>
          </div>
        </section>
        {User && <UserBoard User={User} />}
      </main>
    </>
  );
};

export default Dashboard;
