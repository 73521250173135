import { useState } from "react";
import axios from "axios";
import { API } from "../../exports";
import { useNavigate } from "react-router-dom";
import err from "../../dist/Component 65.png";

const CancelMembership = (props) => {
  const navigate = useNavigate();
  const [updateState, setUState] = useState("");

  const update = async (e) => {
    e.preventDefault();
    let cancel = window.confirm("Are you sure?");
    if (cancel === true) {
      setUState("Removing membership...");
      await axios.delete(API + props.id);
      setUState("Account deleted successfully");
      navigate("/user/new");
    } else {
      return null;
    }
  };

  return (
    <section style={{ display: `${props.show}` }} className="overlay">
      <form encType="multipart/form-data">
        <span>
          <h4>Cancel Membership</h4>{" "}
          <i
            onClick={() => {
              props.show("");
            }}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>

        <hr />
        <div className=" flex flex-col items-center">
          <img src={err} alt="alt" className="py-4" />
          <p className="text-red-400 text-18 font-bold  mt-2">{updateState}</p>
          <p className="text-primary text-20 ">
            Would you really like to cancel your Membership?
          </p>

          <p className="text-red-500 text-20">
            This would delete account and erase all your data, Continue?
          </p>
          <aside>
            <button className="btn-2" onClick={update}>
              Yes, Continue
            </button>
            <button
              className="btn-1"
              onClick={() => {
                props.show("");
              }}
            >
              Go Back
            </button>
          </aside>
        </div>
      </form>
    </section>
  );
};

export default CancelMembership;
