import { Fragment } from "react";
import pexel from "../../dist/pexels 2.png";

const BoxItem = ({ title, children }) => {
  return (
    <aside className="mx-auto text-center lg:text-left bg-white w-64 pl-4 py-3 pr-9 rounded text-sm text-tetiary my-2">
      <h3 className="text-lg font-medium text-secondary ">{title}</h3>
      <p>{children}</p>
    </aside>
  );
};

const Features = () => {
  return (
    <Fragment>
      <section className="lg:px-28 px-7 py-16 bg-transblue flex flex-col lg:flex-row items-center justify-between">
        <aside className="hidden lg:block lg:w-2/6">
          <img className="" src={pexel} alt="first pic" />
        </aside>
        <article className="lg:w-3/5 pt-4 ">
          <h2 className="font-bold text-xl lg:text-4xl px-2 lg:px-0 py-2 lg:py-1 w-56 lg:w-full">
            Why choose Retala Affiliate Program ?
          </h2>
          <img
            className="lg:hidden scale-down my-6"
            src={pexel}
            alt="first pic"
          />
          <p className="text-tetiary text-base py-2 lg:py-1 lg:w-11/12">
            The Retala Affiliate Program helps Individuals earn passively. Get
            paid for every first-time subscription, with no referral limit and a
            lifetime attribution.
          </p>

          <div className="flex flex-col lg:flex-row justify-center lg:justify-between lg:flex-wrap">
            <BoxItem title="Maximum Earnings">
              Get paid for every first-time subscription, with no referral limit
              and a lifetime attribution.
            </BoxItem>
            <BoxItem title="Professional Support">
              Gain access to professional support, tutorials, and a dedicated
              Affiliate manager.
            </BoxItem>
            <BoxItem title="Intuitive Dashboards">
              Easy-to-use dashboards to launch, manage and monitor campaigns.
            </BoxItem>
            <BoxItem title="Creative Resources">
              A powerful high-performing creative App to promote and help make
              peoples live better.
            </BoxItem>
          </div>
        </article>
      </section>
    </Fragment>
  );
};

export default Features;
