import { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../../css/mobile.module.css";

import { UserContext } from "../../context/marketercontext";
import { ModalContext } from "../../context/overlaycontext";

const MobileProfile = () => {
  const { User, Loading } = useContext(UserContext);
  const { OpenModal } = useContext(ModalContext);

  const imgUrl = `${process.env.REACT_APP_IMG_URL}${User.avatar}`;

  return (
    <>
      {Loading && <div className="loader"></div>}
      {User && (
        <main className={`${styles.profile} pt-6 px-4`}>
          <Link to={`/account/dashboard&mobile`}>
            <i className="bx bx-arrow-back bx-sm"></i>
          </Link>
          <h2 className="flex items-center gap-x-2 py-4 text-18 font-semibold">
            <i className="bx bx-user bx-sm"></i> Profile
          </h2>
          <div className={styles.pencil}>
            <img src={imgUrl} alt="hdihdfbfd"></img>
            <i className={`bx bx-pencil bx-sm`}></i>
          </div>

          <i
            onClick={() => OpenModal(6)}
            className={`${styles.edit} bx bx-edit bx-sm`}
          ></i>
          <div>
            <aside className={styles.info}>
              <p className={styles.title}>Fullname</p>
              <p className={styles.value}>{User?.fullname}</p>
            </aside>
            <aside className={styles.info}>
              <p className={styles.title}>Email Address</p>
              <p className={styles.value}>{User?.email}</p>
            </aside>
            <aside className={styles.info}>
              <p className={styles.title}>Password</p>
              <p className={styles.value}>**************</p>
            </aside>
            {User?.school && (
              <aside className={styles.info}>
                <p className={styles.title}>Institution</p>
                <p className={styles.value}>{User?.school}</p>
              </aside>
            )}
          </div>
        </main>
      )}
    </>
  );
};

export default MobileProfile;
