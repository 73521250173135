import styles from "../../css/mobile.module.css";

const Statistics = ({ changePage }) => {
  return (
    <>
      <aside className={styles.boxes}>
        <div className={styles.title}>
          <p>Total Referrals</p>
          <button>
            View All <i className="bi bi-chevron-right"></i>
          </button>
        </div>
        <div className={styles.number}>
          <i className="bx bxs-user bx-sm"></i>
          <p>0</p>
        </div>
      </aside>
      <aside className={styles.boxes}>
        <div className={styles.title}>
          <p>Paid Referrals</p>{" "}
          <button>
            View All <i className="bi bi-chevron-right"></i>
          </button>
        </div>

        <div className={styles.number}>
          <i className="bx bxs-check-circle bx-sm"></i>
          <p>0</p>
        </div>
      </aside>
      <aside className={styles.boxes}>
        <div className={styles.title}>
          <p className={styles.title}>This Month</p>{" "}
          <button>
            View All <i className="bi bi-chevron-right"></i>
          </button>
        </div>
        <div className={styles.number}>
          <i className="bx bx-money bx-sm"></i>
          <p>+N0.00</p>
        </div>
      </aside>
      <button onClick={() => changePage("3")} className={styles.more}>
        More Information <i className="bi bi-chevron-right"></i>
      </button>
    </>
  );
};

export default Statistics;
