import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import Swup from 'swup';

// const swup = new Swup();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
