import pexel from "../../dist/pexels 1.png";
import bullet from "../../dist/bullet.png";

const Header = () => {
  return (
    <>
      <section className="m-auto px-7 lg:px-28 flex flex-col lg:flex-row items-center  justify-between">
        <article className="text-center lg:text-left lg:w-3/5 ">
          <span className="">
            <h3 className="font-bold text-secondary text-lg lg:text-4xl lg:py-1">
              Earn Up to
            </h3>
            <h2 className="font-bold text-xl lg:text-5xl py-1 lg:py-4 ">
              100k Every Month with <br />
              Retala Affiliate Program
            </h2>
          </span>
          <p className=" text-tetiary py-3 lg:py-1 lg:w-5/6">
            If you are looking to build a passive source of online income,Retala
            affiliate marketing can be a great way to monitize - by signing up a
            customer.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              window.location.replace("/account/signup");
            }}
            className="hidden lg:block"
          >
            <input
              className="h-12 my-5 py-1 px-4 w-64 rounded outline-0 bg-transblue"
              type="email"
              name="email"
              disabled
              value=""
              placeholder="Click to Sign up Now!"
            />
            <button className="h-12 px-6 rounded bg-primary text-white font-semibold">
              Register Now
            </button>
          </form>
          <div className="hidden text-tetiary text-xs lg:text-base lg:flex items-center py-2">
            <p className="flex items-center ">
              <img className="pr-2 " src={bullet} alt="bullet" /> Detailed
              Reporting
            </p>
            <p className="flex items-center px-2">
              <img className="pr-2" src={bullet} alt="bullet" /> 0% Admin Costs
            </p>
            <p className="flex items-center">
              <img className="pr-2" src={bullet} alt="bullet" /> Fast Payments
            </p>
          </div>
        </article>
        <aside className=" lg:w-2/6 py-2 lg:py-0">
          <img className="scale-down" src={pexel} alt="first pic" />
        </aside>
        <div className="lg:hidden text-tetiary text-xxs lg:text-base flex items-center py-4">
          <p className="flex items-center ">
            <img className="pr-1 " src={bullet} alt="bullet" /> Detailed
            Reporting
          </p>
          <p className="flex items-center px-2">
            <img className="pr-1" src={bullet} alt="bullet" /> 0% Admin Costs
          </p>
          <p className="flex items-center">
            <img className="pr-1" src={bullet} alt="bullet" /> Fast Payments
          </p>
        </div>
        <a
          href="/account/signup"
          className="lg:hidden my-8 py-2 px-16 rounded bg-primary text-white cursor-pointer "
        >
          Get Started
        </a>
      </section>
    </>
  );
};

export default Header;
