import styles from "../../css/mobile.module.css";

const Activity = ({ Referral, changePage }) => {
  return (
    <>
      <div className="notice flex justify-between pt-2">
        <p className="font-semibold text-18">Activity</p>
        <button
          onClick={() => changePage("1")}
          className=" bg-transblue rounded-full text-xs px-2"
        >
          <i className="bi bi-chevron-left "></i> Back to home
        </button>
      </div>
      <section className="all">
        {Referral.length === 0 ? (
          <div className="text-center leading-10 my-20">
            No Referral Rercords
          </div>
        ) : (
          <aside className={`  my-4 pb-2 border-b-transblue border-b-2`}>
            <span className="flex justify-between text-14">
              <p className=" font-semibold">Jane Adesoya</p>
              <p className="  text-green-400">+N750</p>
            </span>
            <p className="text-sm py-1">activated Pro plan using your code</p>
            <button className="text-sm text-tetiary">3 days ago</button>
          </aside>
        )}
      </section>
      <button onClick={() => changePage("1")} className={styles.more}>
        <i className="bi bi-chevron-left"></i> Back to Home
      </button>
    </>
  );
};

export default Activity;
