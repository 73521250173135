import { useState, useContext } from "react";

import axios from "axios";

import { ModalContext } from "../../context/overlaycontext";

import err from "../../dist/Component 65.png";

const MobileCancel = ({ User }) => {
  const { ToggleModalOff } = useContext(ModalContext);
  const [Loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    let cancel = window.confirm("Are you sure?");
    if (cancel === true) {
      setLoading(true);
      await axios.delete(process.env.REACT_APP_USER_URL + User._id);
      setLoading(false);
      window.location.replace("/account/signup");
    } else {
      return null;
    }
  };

  return (
    <section className="overlay-mobile">
      <form onSubmit={handleUpdate} encType="multipart/form-data">
        <span>
          <h4>Cancel Membership</h4>{" "}
          <i
            onClick={ToggleModalOff}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>
        <hr />

        <div className=" flex flex-col items-center text-center">
          <img src={err} alt="alt" className="py-2" />
          {/* <p className="text-red-400 text-18 font-bold  mt-2">{updateState}</p> */}
          <p className="text-primary text-18 pb-1">
            Would you really like to cancel your Membership?
          </p>

          <p className="text-red-500 text-18 pb-1">
            This would delete account and erase all your data, Continue?
          </p>

          <button className="btn-2">
            {Loading ? "Processing" : "Cancel Membership"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default MobileCancel;
