import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import pexel from "../assets/affiliate.png";

const SignIn = () => {
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState("");
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  }

  function handleSubmit(event) {
    event.preventDefault();
    let device = window.innerWidth;
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_USER_URL}login`, state)
      .then(({ data }) => {
        localStorage.setItem("userId", data);
        setLoading(false);
        if (device < 960) {
          window.location.replace(`/account/dashboard&mobile`);
        } else {
          window.location.replace(`/account/dashboard`);
        }
      })
      .catch((err) => {
        setLoading(false);
        // eslint-disable-next-line
        if (err == "Error: Request failed with status code 401") {
          setError("Invalid Credentials");
        } else {
          setError(" Network Error  ");
        }
        setTimeout(function () {
          setError("");
        }, 2000);
      });
  }
  return (
    <>
      <section className="newsign px-4 lg:px-28 h-customScreen lg:flex lg:gap-x-14 items-center justify-between">
        <form onSubmit={handleSubmit}>
          <div className="mobile py-4 block lg:hidden">
            <h2 className="py-3">Retala Affiliate Programme</h2>
            <img className="" src={pexel} alt="shdfjgh" />
          </div>
          <h2>Login</h2>
          <p>Please fill out your details</p>
          <div className="text-red-500 font-bold text-16 lg:text-xl">
            {Error}
          </div>
          <input
            type="email"
            value={state.email}
            name="email"
            required
            placeholder="Enter your Email"
            onChange={handleChange}
          />
          <br />
          <input
            type="password"
            value={state.password}
            name="password"
            required
            placeholder="Enter Password"
            onChange={handleChange}
          />
          <br />
          <button>
            {Loading ? "Authenticating..." : "Login"}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="22"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </button>
          {/* <p className="hrline">
            <hr />
            OR
            <hr />
          </p>
          <button className="google">Login with Google</button> */}
          <p className="last">
            Don't have an account?{" "}
            <Link to="/account/signup">Sign Up instead</Link>
          </p>
        </form>
        <img className="hidden lg:block" src={pexel} alt="shdfjgh" />
      </section>

      <aside className="sticky-footer hidden lg:block">
        <Link to="#tgfgr">Terms of service</Link>|
        <Link to="#vfggdf">Privacy Policy</Link>
      </aside>
    </>
  );
};

export default SignIn;
