import { useContext } from "react";
import { Link } from "react-router-dom";
import UserBoard from "../components/userboard";
import styles from "../css/profile.module.css";

import { UserContext } from "../context/marketercontext";
import { ModalContext } from "../context/overlaycontext";

const Profile = () => {
  const { User, handleLogout, Loading } = useContext(UserContext);
  const { OpenModal } = useContext(ModalContext);

  return (
    <>
      {Loading && <div className="loader"></div>}
      {User && (
        <main className={styles.profile}>
          <aside className={styles.navigation}>
            <Link to={`/account/dashboard`}>
              <i className="bx bxs-home-alt-2 bx-md"></i>
            </Link>
            <Link className={styles.active} to={`/account/profile`}>
              <i className="bx bxs-user-circle bx-md"></i>
            </Link>
            <Link onClick={handleLogout} className={styles.logout} to="#">
              <i title="logout" className="bx bx-power-off bx-md"></i>
            </Link>
          </aside>

          <section className={styles.view}>
            <span>
              <h2>
                <i className="bx bxs-user-circle bx-md"></i> My Profile
              </h2>
              <i
                onClick={() => OpenModal(6)}
                className="bi bi-pencil-square cursor-pointer"
              ></i>
            </span>
            <div className={styles.list}>
              <aside className={styles.listItem}>
                <p className={styles.title}>Fullname</p>
                <p className={styles.value}>{User.fullname}</p>
              </aside>
              <aside className={styles.listItem}>
                <p className={styles.title}>Email Address</p>
                <p className={styles.value}>{User.email}</p>
              </aside>
              {User?.school && (
                <aside className={styles.listItem}>
                  <p className={styles.title}>Institution</p>
                  <p className={styles.value}>{User.school}</p>
                </aside>
              )}
            </div>

            <div className={styles.account}>
              <h2>
                <i className="bx bxs-user bx-md"></i> Account
              </h2>

              <section className={styles.box}>
                <h4>Payment Details</h4>
                <section className={styles.table}>
                  <div>
                    <p>Bank Name</p>
                    <p>{User.bank}</p>
                  </div>
                  <div>
                    <p>Bank Account Number</p>
                    <p>{User.acct_num}</p>
                  </div>
                  <div>
                    <p>Account Holder's Name</p>
                    <p>{User.acct_name}</p>
                  </div>
                  <button onClick={() => OpenModal(7)}>
                    Update Bank Details
                  </button>
                </section>
              </section>
            </div>
          </section>
          {User && <UserBoard User={User} />}
        </main>
      )}
    </>
  );
};

export default Profile;
