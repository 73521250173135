import { Fragment } from "react";
import Logo from "../../dist/retala.png";
import icon from "../../dist/Social Icons.svg";
import icon1 from "../../dist/Social Icons-1.svg";
import icon2 from "../../dist/Social Icons-2.svg";
import icon3 from "../../dist/Social Icons-3.svg";

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <Fragment>
      <article className="bg-transblue">
        <div className="size footer text-rtl_blue flex justify-between px-7 lg:px-28 py-9">
          <div className="flex flex-col lg:flex-row justify-between w-size">
            <div className="py-4 lg:py-0 pl-3">
              <img className="mb-5" width="100px" src={Logo} alt="logo" />
              <p className="mb-2">Copyright © {currentYear} Retala.</p>
              <p className="mt-0">All rights reserved.</p>
              <p className="flex mt-5">
                <a href="https://www.instagram.com/retalapecksas">
                  <img className="mr-3" src={icon} title="instagram" alt="" />
                </a>
                <a href="https://web.facebook.com/retalapecksas">
                  <img className="mr-3" src={icon1} title="facebook" alt="" />
                </a>
                <a href="https://twitter.com/retalapecksas">
                  <img className="mr-3" src={icon2} title="twitter" alt="" />
                </a>
                <a href="https://www.youtube.com/channel/UCjT42aKsxvjDGFpXvWtXCng">
                  <img className="mr-3" src={icon3} title="youtube" alt="" />
                </a>
              </p>
            </div>
            <div className="list flex justify-between">
              <div className="pl-3 pt-4 lg:pt-0 lg:px-20 ">
                <h4 className="font-semibold text-rtl_black text-lg pb-4">
                  Company
                </h4>
                <ul>
                  <li>
                    <a className="nav-link cursor" href="https://retala.app">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link cursor"
                      href="https://blog.retala.app"
                    >
                      Blog
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link cursor"
                      href="https://support.retala.app"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link cursor"
                      href="https://retala.app/#pricing"
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className="nav-link cursor hidden" href="!#">
                      Testimonials
                    </a>
                  </li>
                </ul>
              </div>
              <div className="px-8 pt-4">
                <h4 className="font-semibold text-rtl_black text-lg pb-4">
                  Support
                </h4>
                <ul>
                  <li>
                    <a
                      className="nav-link cursor"
                      href="https://support.retala.app"
                    >
                      Help center
                    </a>
                  </li>
                  <li>
                    <a className="nav-link cursor" href="!#">
                      Terms of service
                    </a>
                  </li>

                  <li>
                    <a className="nav-link cursor" href="!#">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden  px-4 pb-8">
              <h4 className="font-semibold text-rtl_black text-xl pt-4 lg:pt-0 pb-4">
                Stay up to date
              </h4>
              <input type="email" placeholder="Your email address" />
            </div>
          </div>
        </div>
      </article>
    </Fragment>
  );
};

export default Footer;
