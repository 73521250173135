import { useEffect } from "react";
import error from "../dist/404.jpg";

const NotFound = () => {
  useEffect(() => {
    setTimeout(window.history.go(-1), 4000);
  });

  return (
    <div className="NF">
      <img src={error} alt="alt" className=" lg:h-screen w-screen" />
    </div>
  );
};

export default NotFound;
