import retala from "../../dist/retala.png";

const Menu = ({ menu, setMenu }) => {
  const Reset = () => setMenu(false);

  return (
    <>
      <aside style={{ width: menu ? "13em" : "0" }} className="menu">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          fill="currentColor"
          className="bi bi-x absolute right-4"
          viewBox="0 0 16 16"
          onClick={Reset}
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
        <div className="flex flex-col items-start font-bold text-xl px-7 pt-14 pb-4">
          <img className="my-11" src={retala} alt="retala logo" />
          <a href="/" onClick={Reset} className="cursor-pointer my-5 ">
            Home
          </a>
          <a
            href="/account/login"
            onClick={Reset}
            className="cursor-pointer my-5"
          >
            Login
          </a>
          <a href="/#features" onClick={Reset} className="cursor-pointer my-5 ">
            Features
          </a>
          <a
            href="/account/signup"
            onClick={Reset}
            className="cursor-pointer my-5 "
          >
            Register
          </a>
        </div>
      </aside>
    </>
  );
};
export default Menu;
