import { useState } from "react";
import { Link } from "react-router-dom";
import pexel from "../assets/affiliate.png";
import pixel from "../assets/student.png";
import pix from "../assets/marketer.png";

const RegisterIndex = () => {
  const [Img, setImg] = useState(pexel);
  const [bool, setBool] = useState(true);

  let handleSwitch = () => {
    if (bool) {
      setImg(pixel);
      setBool(!bool);
    } else {
      setImg(pix);
      setBool(!bool);
    }
  };
  // let moue = () => {
  //   setCurrentImg(pexel);
  // };
  return (
    <>
      <section className="regIndex px-4 lg:px-28 h-customScreen lg:flex lg:gap-x-14 items-center justify-between">
        <article className="py-6 ">
          <h2>How would you like to work with us?</h2>
          <p className="text-center lg:text-left">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nam
            porro placeat.Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Modi nam porro placeat.
          </p>

          <img
            className="block px-6 py-4 lg:hidden"
            src={pexel}
            alt="collaborate pic"
          />

          <Link to="/account/signup/student" className="box">
            <div>
              <p>Student Affiliate Program</p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere
                ea possimus odio.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-arrow-right hidden lg:block"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </Link>
          <Link to="/account/signup/marketer" className="box">
            <div>
              <p>Affiliate Marketer Program</p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere
                ea possimus odio.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-arrow-right hidden lg:block"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </Link>
          <p className="last">
            Already registered? <Link to="/account/login">Login Here</Link>
          </p>
        </article>
        <img
          className="hidden  lg:block transition-all"
          onMouseOver={handleSwitch}
          onMouseLeave={() => setImg(pexel)}
          src={Img}
          alt="collaborate pic"
        />
      </section>
      <aside className="sticky-footer">
        <Link to="#tgfgr">Terms of service</Link> |
        <Link to="#vfggdf">Privacy Policy </Link>
      </aside>
    </>
  );
};

export default RegisterIndex;
