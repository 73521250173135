import { useState, useContext } from "react";
import axios from "axios";

import { ModalContext } from "../../context/overlaycontext";

import data from "../institutions.json";

const MobileAccountEdit = ({ User }) => {
  const { ToggleModalOff } = useContext(ModalContext);

  let initial = {
    fullname: User.fullname,
    email: User.email,
    school: User.school,
  };

  const [Input, setInput] = useState(initial);
  const [Loading, setLoading] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setInput({ ...Input, [name]: value });
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    // setUState("Updating Details...");
    let values = {};
    if (Input.email !== User.email) {
      values.email = Input.email;
    }
    if (Input.fullname !== User.fullname) {
      values.fullname = Input.fullname;
    }

    if (Input.school !== User.school) {
      values.school = Input.school;
    }

    if (values.length === 0) {
      return null;
    } else {
      setLoading(true);
      axios
        .patch(process.env.REACT_APP_USER_URL + User._id, { values })
        .then(() => {
          setLoading(false);
          window.location.reload();
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
    // setUState("Details Updated successfully");
    // ToggleModalOff();
  };

  return (
    <section className="overlay-mobile">
      <form onSubmit={handleUpdate} encType="multipart/form-data">
        <span>
          <h4>Update Account Details</h4>
          <i
            onClick={ToggleModalOff}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>
        <hr />
        <section className="flex flex-col items-center">
          <div>
            <label>Fullname</label>
            <input
              type="text"
              name="fullname"
              value={Input.fullname}
              onChange={handleChange}
            />

            <label>Email Address</label>
            <input
              type="email"
              name="email"
              value={Input.email}
              onChange={handleChange}
            />

            {User?.school && (
              <>
                <label>Institution</label>
                <input
                  type="text"
                  name="school"
                  list="school"
                  value={Input.school}
                  onChange={handleChange}
                />
                <datalist id="school">
                  {data.map((school) => (
                    <option key={school.id} value={school.name}>
                      {school.name}
                    </option>
                  ))}
                </datalist>
              </>
            )}
          </div>

          <button className="btn-1">
            {" "}
            {Loading ? "Processing" : "Update Details"}
          </button>
        </section>
      </form>
    </section>
  );
};

export default MobileAccountEdit;
