import { useState } from "react";
import axios from "axios";
import { API } from "../../exports";

const EditAvatar = (props) => {
  const [avatar, setavt] = useState("");

  const upload = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("avatar", avatar);
    console.log(data);
    await axios.patch(API + props.id, data).catch((e) => console.error(e));

    props.show("");
  };

  return (
    <section style={{ display: `${props.show}` }} className="overlay">
      <form
        style={{ display: `${props.show}` }}
        className="upload-prompt"
        encType="multipart/form-data"
      >
        <span>
          <h4>Upload Image</h4>{" "}
          <i
            onClick={() => {
              props.show("");
            }}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>
        <hr />
        <div className=" pl-6">
          <input
            type="file"
            onChange={(e) => {
              var file = e.target.files[0];
              setavt(file);
            }}
            name="avatar"
            id="avatar"
            className="inputfile"
          />
        </div>
        <button className="btn-1" onClick={upload}>
          Upload
        </button>
      </form>
    </section>
  );
};

export default EditAvatar;
