import { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../../css/mobile.module.css";

import { UserContext } from "../../context/marketercontext";
import { ModalContext } from "../../context/overlaycontext";

const WalletMobile = () => {
  // const { id } = useParams();
  const { User, Loading } = useContext(UserContext);
  const { OpenModal } = useContext(ModalContext);

  const imgUrl = `${process.env.REACT_APP_IMG_URL}${User.avatar}`;

  return (
    <>
      {Loading && <div className="loader"></div>}
      {User && (
        <main className={`${styles.profile} pt-6 px-4`}>
          <Link to={`/account/dashboard&mobile`}>
            <i className="bx bx-arrow-back bx-sm"></i>
          </Link>
          <h2 className="flex items-center gap-x-2 py-4 text-18 font-semibold">
            <i className="bx bx-wallet bx-sm"></i> Wallet
          </h2>
          <div
            className={`${styles.pencil} mb-2 border-b-2 border-b-transblue`}
          >
            <img src={imgUrl} alt="hdihdfbfd"></img>
            <i className={`bx bx-pencil bx-sm`}></i>
          </div>

          <div className={`${styles.walletinfo}  text-center leading-7`}>
            <p className="font-semibold">{User.fullname}</p>
            <p className="text-tetiary">Wallet Balance</p>
            <h2 className="font-semibold text-2xl pb-3">
              N{User?.wallet_amt}.00
            </h2>
          </div>

          <div className="bg-transblue rounded-xl p-4 ">
            <i
              onClick={() => OpenModal(7)}
              className={`${styles.edit} bx bx-edit bx-sm`}
            ></i>
            <section className={`${styles.wallettable} text-tetiary text-14`}>
              <div className="flex items-center justify-between py-2 text-tetiary">
                <p>Bank Name</p>
                <p>{User?.bank}</p>
              </div>
              <div className="flex items-center justify-between py-2">
                <p>Account Number</p>
                <p>{User?.acct_num}</p>
              </div>
              <div className="flex items-center justify-between py-2">
                <p>Account Name</p>
                <p>{User.acct_name}</p>
              </div>
            </section>
          </div>
          <button className={styles.walletlogout}>Withdraw Funds</button>
          <button className={styles.walletlogouttwo}>Schedule Payment</button>
        </main>
      )}
    </>
  );
};

export default WalletMobile;
