import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landingpage";
import Forward from "./pages/form";
import Marketer from "./pages/marketer";
import Student from "./pages/student";
import RegisterIndex from "./pages/signup";
import SignIn from "./pages/signin";

import MobileAuth from "./auth/mobile";
import DesktopAuth from "./auth/desktop";
import NotFound from "./pages/404page";
import NavBar from "./pages/landing.components/navbar";
import Menu from "./pages/landing.components/mobile-menu";

import "./styles.css";
import "./queries.css";
import Profile from "./pages/profile";
import MobileProfile from "./pages/mobile/profile";
import Settings from "./pages/mobile/settings";
import DashboardMenu from "./pages/mobile/menu";
import WalletMobile from "./pages/mobile/wallet";
import MarketerContext from "./context/marketercontext";
import OverlayContext from "./context/overlaycontext";
import MainOverlay from "./overlays/main";

function App() {
  const [menu, setMenu] = useState(false);

  function SwitchMenu() {
    let current = window.location.pathname;
    let currentMenu = current.substring(current.length - 16);
    switch (currentMenu) {
      case "dashboard&mobile":
        return <DashboardMenu setMenu={setMenu} menu={menu} />;
      default:
        return <Menu setMenu={setMenu} menu={menu} />;
    }
  }

  return (
    <MarketerContext>
      <OverlayContext>
        <Router>
          <div className="App  pt-20 lg:pt-20">
            <section className="fixed w-screen top-0 left-0">
              <NavBar setMenu={setMenu} />
            </section>
            <section>{SwitchMenu()}</section>
            <MainOverlay />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/account/dashboard" element={<DesktopAuth />} />
              <Route path="/account/profile" element={<Profile />} />
              <Route path="/account/signup" element={<RegisterIndex />} />
              <Route path="/account/signup/x" element={<Forward />} />
              <Route path="/account/signup/student" element={<Student />} />
              <Route path="/account/signup/marketer" element={<Marketer />} />
              <Route path="/account/signup/:email" element={<Marketer />} />
              <Route path="/account/login" element={<SignIn />} />
              {/* <Route path="/refferals/:code" element={<MobileDashboard />} /> */}
              <Route
                path="/account/dashboard&mobile"
                element={<MobileAuth />}
              />
              <Route
                path="/account/profile/dashboard&mobile"
                element={<MobileProfile />}
              />
              <Route
                path="/account/wallet/dashboard&mobile"
                element={<WalletMobile />}
              />
              <Route
                path="/account/settings/dashboard&mobile"
                element={<Settings />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </OverlayContext>
    </MarketerContext>
  );
}

export default App;
