import { Link } from "react-router-dom";
import pixel from "../assets/pexels.png";
import pexel from "../assets/marketer.png";

import data from "./institutions.json";

const Forward = ({
  Error,
  Loading,
  handleChange,
  handleSubmit,
  show,
  state,
}) => {
  const display = show;

  return (
    <>
      <section className="newsign px-4 lg:px-28 h-customScreen lg:flex lg:gap-x-14 items-center justify-between">
        <div className="mobile pb-6 block lg:hidden">
          <h2 className="py-2">
            {display
              ? "Retala Student Affiliate Program"
              : "Retala Affiliate Marketer Program"}
          </h2>
          <img src={display ? pixel : pexel} alt="shdfjgh" />
        </div>
        <form onSubmit={handleSubmit}>
          <h2 className="hidden lg:block">
            {display === true
              ? "Retala Student Affiliate Program"
              : "Retala Affiliate Marketer Program"}
          </h2>
          <p>Please fill out additional information.</p>
          <div className="text-red-500 font-bold text-16 lg:text-xl">
            {Error}
          </div>
          <input
            type="number"
            value={state.phone}
            name="phone"
            required
            onChange={handleChange}
            placeholder="Enter your Phone number"
          />
          <br />

          {display && (
            <>
              <input
                type="text"
                value={state.school}
                name="school"
                list="school"
                required
                onChange={handleChange}
                placeholder="Name of institution"
              />
              <datalist id="school">
                {data.map((school) => (
                  <option key={school.id} value={school.name}>
                    {school.name}
                  </option>
                ))}
              </datalist>
            </>
          )}
          <br />
          <button>
            {Loading ? "Processing..." : "Submit"}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="22"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </button>
        </form>
        <img
          className="hidden lg:block"
          src={display ? pixel : pexel}
          alt="shdfjgh"
        />
      </section>
      <aside className="sticky-footer">
        <Link to="#tgfgr">Terms of service</Link> |
        <Link to="#vfggdf">Privacy Policy </Link>
      </aside>
    </>
  );
};

export default Forward;
