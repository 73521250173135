import React, { createContext } from "react";
import axios from "axios";

import useFetch from "../hooks/useFetch";

export const UserContext = createContext();

const MarketerContext = (props) => {
  const Id = localStorage.getItem("userId") || "";
  const { User, Referral, Loading } = useFetch(Id);

  const handleLogout = () => {
    axios
      .patch(`${process.env.REACT_APP_USER_URL}logout/${Id}`)
      .then(() => {
        localStorage.removeItem("userId");
        window.location.replace("/account/login");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const global = { User, Referral, Loading, handleLogout };

  return (
    <UserContext.Provider value={{ ...global }}>
      {props.children}
    </UserContext.Provider>
  );
};
export default MarketerContext;
