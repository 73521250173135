import styles from "../../css/mobile.module.css";

const Notifications = ({ Referral, changePage }) => {
  return (
    <>
      <div className="notice flex justify-between pt-2">
        <p className="font-semibold text-18">Notifications</p>
        <button
          onClick={() => changePage("1")}
          className=" bg-transblue rounded-full text-xs px-2"
        >
          <i className="bi bi-chevron-left "></i> Back to home
        </button>
      </div>
      <section className="all">
        {Referral.length === 0 ? (
          <div className="text-center leading-10 my-20">
            No Notification Rercords
          </div>
        ) : (
          <aside className={`${styles.new} bg-transblue rounded-lg p-4 my-2`}>
            <p className="text-sm font-semibold">Way to Go Evare!</p>
            <p className="text-sm py-2">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima
              possimus suscipit quod perferendis.
            </p>
            <button className="">Mark as read</button>
          </aside>
        )}
      </section>
      <button onClick={() => changePage("1")} className={styles.more}>
        <i className="bi bi-chevron-left"></i> Back to Home
      </button>
    </>
  );
};

export default Notifications;
