import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bell from "../../assets/bell.png";
import Activity from "../../components/mobile/activity";
import Notifications from "../../components/mobile/notifications";
import Statistics from "../../components/mobile/statistics";
import styles from "../../css/mobile.module.css";

const MobileDashboard = ({ User, Referral }) => {
  const imgUrl = `${process.env.REACT_APP_IMG_URL}${User.avatar}`;

  const [currentPage, setCurrentPage] = useState("1");

  function copyToClipboard() {
    let firstline = `Hey! Sign up for Retala using my refferal code ${User.unique_code}.`;
    let nextline = " \nUse this code to register at retala.app";
    let text = `${firstline}${nextline}`;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        window.alert(text);
      })
      .catch(() => {
        window.alert("something went wrong");
      });
  }

  function changePage(page) {
    setCurrentPage(page);
  }

  useEffect(() => {
    setCurrentPage((prevState) => prevState);
  }, [currentPage]);

  function Page() {
    switch (currentPage) {
      case "1":
        return <Statistics changePage={changePage} />;
      case "2":
        return <Notifications Referral={Referral} changePage={changePage} />;
      case "3":
        return <Activity Referral={Referral} changePage={changePage} />;
      default:
        return <div>Invalid Page</div>;
    }
  }

  return (
    <>
      <main className="pt-8 px-4">
        <div className={`${styles.head} flex items-center justify-between`}>
          <span className="flex items-center gap-x-4">
            <Link to={`/account/profile/dashboard&mobile`}>
              <img src={imgUrl} alt="fgdhf" />
            </Link>{" "}
            <h2>My Dashboard</h2>
          </span>
          <img onClick={() => changePage("2")} src={bell} alt="bell" />
        </div>
        <div
          onClick={() => copyToClipboard("CR411-304")}
          className={`${styles.code} flex items-center justify-between my-3 py-6 px-4 rounded-2xl`}
        >
          <p className="text-14">Affiliate Code</p>
          <p className="flex items-center justify-between">
            <i className="bx bxs-copy  pr-1"></i>
            {User?.unique_code}
          </p>
        </div>

        <section className={styles.switch}>{Page()}</section>
      </main>
    </>
  );
};

export default MobileDashboard;
