import { useContext } from "react";
import AccountEdit from "./desktop/account";
import BankEdit from "./desktop/bank";
import CancelMembership from "./desktop/cancel";
import EditAvatar from "./desktop/avatar";
import Withdraw from "./desktop/withdraw";
import MobileAccountEdit from "./mobile/account";
import MobileCancel from "./mobile/cancel";
import MobileBankDetails from "./mobile/bank";

import { UserContext } from "../context/marketercontext";
import { ModalContext } from "../context/overlaycontext";

import "../css/modal.css";

const MainOverlay = () => {
  const { Modal, ToggleModalOff } = useContext(ModalContext);
  const { User } = useContext(UserContext);

  const CurrentModal = (key) => {
    switch (key) {
      case 1:
        return <AccountEdit User={User} />;
      case 2:
        return <BankEdit User={User} />;
      case 3:
        return <EditAvatar User={User} />;
      case 4:
        return <Withdraw User={User} />;
      case 5:
        return <CancelMembership User={User} />;
      case 6:
        return <MobileAccountEdit User={User} />;
      case 7:
        return <MobileBankDetails User={User} />;
      case 8:
        return <MobileCancel User={User} />;
      default:
        return ToggleModalOff;
    }
  };
  return <>{Modal.show && CurrentModal(Modal.current)}</>;
};

export default MainOverlay;
