import { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../../css/mobile.module.css";
import retala from "../../dist/retala.png";

import { UserContext } from "../../context/marketercontext";

const DashboardMenu = ({ menu, setMenu }) => {
  const { User } = useContext(UserContext);

  const imgUrl = `${process.env.REACT_APP_IMG_URL}${User.avatar}`;

  const Reset = () => setMenu(false);

  return (
    <>
      {User && (
        <aside style={{ width: menu ? "13em" : "0" }} className="menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="currentColor"
            className="bi bi-x absolute right-4"
            viewBox="0 0 16 16"
            onClick={Reset}
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>

          <div className="flex flex-col items-start font-bold text-18 px-5 pt-14 pb-4">
            <img src={imgUrl} alt="avatar" className={styles.menuimg} />
            <p className="font-bold  py-4">{User?.fullname}</p>
            <p className="text-tetiary text-14 font-normal">Wallet</p>
            <Link to={`/account/wallet/dashboard&mobile`} onClick={Reset}>
              <p className="font-bold  flex items-center">
                N{User?.wallet_amt && User?.wallet_amt.toLocaleString()}.00
                <i className="bx bx-chevron-right bx-md"></i>
              </p>
            </Link>

            <Link
              to={`/account/dashboard&mobile`}
              onClick={Reset}
              className="cursor-pointer mt-8 my-4 "
            >
              Home
            </Link>
            <Link
              to={`/account/profile/dashboard&mobile`}
              onClick={Reset}
              className="cursor-pointer my-4"
            >
              Profile
            </Link>
            <Link
              to={`/account/settings/dashboard&mobile`}
              onClick={Reset}
              className="cursor-pointer my-4 "
            >
              Settings
            </Link>
            <img
              className="absolute bottom-24"
              src={retala}
              alt="retala logo"
            />
          </div>
        </aside>
      )}
    </>
  );
};
export default DashboardMenu;
