import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Forward from "./form";
import pexel from "../assets/student.png";

const Student = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState("");

  let initialState = {
    fullname: "",
    email: "",
    phone: "",
    password: "",
    type: "Student Marketer",
    school: "",
  };
  const [step, setStep] = useState(1);
  const [state, setState] = useState(initialState);

  function forward(event) {
    event.preventDefault();
    setStep((prevState) => prevState + 1);
  }
  function handleChange(event) {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios
      .post(process.env.REACT_APP_USER_URL, state)
      .then((res) => {
        setLoading(false);
        if (res.data === "done") {
          navigate("/account/login");
        } else {
          setError(res.data);
          setTimeout(function () {
            setError("");
            setStep(1);
          }, 3000);
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <>
      {step === 1 ? (
        <section className="newsign px-4 lg:px-28 h-customScreen lg:flex lg:gap-x-14 items-center justify-between">
          <form onSubmit={forward}>
            <div className="mobile py-2 block lg:hidden">
              <h2 className="py-2">Retala Student Affiliate Program</h2>
              <img src={pexel} alt="shdfjgh" />
            </div>
            <h2 className="hidden lg:block">
              Retala Student Affiliate Program
            </h2>
            <p>Welcome! Please fill out the form.</p>
            <input
              type="text"
              value={state.fullname}
              name="fullname"
              required
              placeholder="Enter your Fullname"
              onChange={handleChange}
            />
            <br />
            <input
              type="email"
              value={state.email}
              name="email"
              required
              placeholder="Enter your Email"
              onChange={handleChange}
            />
            <br />
            <input
              type="password"
              value={state.password}
              name="password"
              required
              placeholder="Enter Password"
              onChange={handleChange}
            />
            <br />
            <button>
              Next{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="22"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </button>
            {/* <p className="hrline">
              <hr />
              OR
              <hr />
            </p>
            <button className="google">Sign Up with Google</button> */}
            <p className="last">
              Already registered? <Link to="/account/login">Login instead</Link>
            </p>
          </form>
          <img className="hidden lg:block" src={pexel} alt="shdfjgh" />
        </section>
      ) : (
        <Forward
          Error={Error}
          Loading={Loading}
          state={state}
          show={true}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
      <aside className="sticky-footer">
        <Link to="#tgfgr">Terms of service</Link> |
        <Link to="#vfggdf">Privacy Policy </Link>
      </aside>
    </>
  );
};

export default Student;
