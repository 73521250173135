export const API = "https://affliate-program.herokuapp.com/api/user/";
export const APIRF =
  "https://affliate-program.herokuapp.com/api/referral/user/";
export const APIMG = "https://affliate-program.herokuapp.com/";
// retalaaffiliate.herokuapp.com/

export default function ButtonComponent(props) {
  return props.page === props.thispage ? (
    <button className="text-primary pl-8 py-3 pr-6 rounded-tr-lg rounded-br-lg  bg-white font-semibold  my-4 flex items-center ">
      <i className={props.icon}></i>
      {props.title}
    </button>
  ) : (
    <button
      onClick={() => {
        props.onClick();
      }}
      className="text-white font-semibold pl-11 py-3 pr-6 my-2 flex items-center "
    >
      <i className={props.icon}></i>
      {props.title}
    </button>
  );
}
