import { useState } from "react";
import axios from "axios";
import { API } from "../../exports";

const Withdraw = ({ props, User }) => {
  const [wallet_amt, setamt] = useState(0);
  const [payState, setPayState] = useState("");

  const update = (e) => {
    e.preventDefault();

    if (wallet_amt >= 100) {
      if (wallet_amt > User.wallet_amt) {
        setPayState("Insufficient Funds");
        setTimeout(() => {
          setPayState("");
        }, 2000);
      } else {
        setPayState("Cannot process payment at the moment...");
        axios.patch(API + props.id, { wallet_amt });
        setTimeout(() => {
          setPayState("");
        }, 2000);
      }
    } else {
      setPayState("Amount must be NGN100 or more.");
      setTimeout(() => {
        setPayState("");
      }, 2000);
    }

    // props.show("");
    // window.location.reload();
  };

  return (
    <section style={{ display: `${props.show}` }} className="overlay">
      <form encType="multipart/form-data">
        <span>
          <h4>Available Balance</h4>{" "}
          <i
            onClick={() => {
              props.show("");
            }}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>
        <hr />
        <div className=" pl-6">
          <h2 className="text-4xl text-black font-bold">
            NGN {User.wallet_amt}.00
          </h2>
          <p className="text-gray mb-8">
            Click here to see your Payment History
          </p>

          <input
            type="number"
            placeholder="Enter Amount to Withdraw"
            className=" text-gray "
            value={wallet_amt}
            onChange={(e) => {
              setamt(e.target.value);
            }}
          />

          <p className="text-secondary text-18 font-bold ml-2 mt-2">
            {payState}
          </p>

          <p className="text-gray mt-4">
            Change Deposit Account?
            <p
              onClick={() => {
                props.show("#bank");
              }}
              className="text-primary text-18 pl-2 "
            >
              Pay to Different Account
            </p>
          </p>
        </div>

        <button className="btn-1" onClick={update}>
          Withdraw
        </button>

        <button className="btn-2 wtdraw">Schedule payment</button>
      </form>
    </section>
  );
};

export default Withdraw;
