import { useState, useContext } from "react";
import axios from "axios";

import { ModalContext } from "../../context/overlaycontext";

const AccountEdit = ({ User }) => {
  const { ToggleModalOff } = useContext(ModalContext);

  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [updateState, setUState] = useState("");

  const update = async (e) => {
    e.preventDefault();
    setUState("Updating Details...");
    if (email !== "") {
      await axios.patch(process.env.REACT_APP_USER_URL + User._id, { email });
    }
    if (number !== "") {
      await axios.patch(process.env.REACT_APP_USER_URL + User._id, { number });
    }

    setUState("Details Updated successfully");
  };

  return (
    <section className="overlay">
      <form encType="multipart/form-data">
        <span>
          <h4>Update Account Details</h4>
          <i
            onClick={ToggleModalOff}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>
        <hr />
        <div className=" pl-6">
          <label>New Email Address</label>
          <input
            type="text"
            value={email}
            onChange={(e) => {
              setemail(e.target.value);
            }}
          />

          <label>New Phone Number</label>
          <input
            type="number"
            value={number}
            onChange={(e) => {
              setnumber(e.target.value);
            }}
          />
        </div>
        <p className="text-green-400 text-18 font-bold ml-7 mt-2">
          {updateState}
        </p>
        <button className="btn-1" onClick={update}>
          Update Details
        </button>
        <button className="btn-2" onClick={ToggleModalOff}>
          Cancel
        </button>
      </form>
    </section>
  );
};

export default AccountEdit;
