import { useContext } from "react";
import Dashboard from "../pages/dashboard";

import { UserContext } from "../context/marketercontext";

const DesktopAuth = () => {
  const { User, Referral, handleLogout } = useContext(UserContext);

  const renderCurrentPage = (login) => {
    if (login) {
      return (
        <Dashboard
          User={User}
          Referral={Referral}
          handleLogout={handleLogout}
        />
      );
    } else if (login === false) {
      return window.location.replace("/account/login");
    } else {
      return <div className="loader"></div>;
    }
  };

  return <>{User && renderCurrentPage(User.login)}</>;
};

export default DesktopAuth;
