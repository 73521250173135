import { useState } from "react";
import axios from "axios";
import { API } from "../../exports";

const BankEdit = (props) => {
  const [bank, setbank] = useState("");
  const [acct_name, setname] = useState("");
  const [acct_num, setnum] = useState("");
  const [updateState, setUState] = useState("");

  const update = async (e) => {
    e.preventDefault();

    if (bank !== "") {
      setUState("Updating Details...");
      await axios.patch(API + props.id, { bank });
    }
    if (acct_name !== "") {
      setUState("Updating Details...");
      await axios.patch(API + props.id, { acct_name });
    }
    if (acct_num !== "") {
      setUState("Updating Details...");
      await axios.patch(API + props.id, { acct_num });
    }

    setUState("Details Updated successfully");
    props.show("");
    // window.location.reload();
  };

  return (
    <section style={{ display: `${props.show}` }} className="overlay">
      <form encType="multipart/form-data">
        <span>
          <h4>Update Bank Details</h4>{" "}
          <i
            onClick={() => {
              props.show("");
            }}
            className="bx bx-x bx-md cursor-pointer"
          ></i>
        </span>
        <hr />
        <div className=" pl-6">
          <label> Bank Name </label>
          <input
            type="text"
            value={bank}
            onChange={(e) => {
              setbank(e.target.value);
            }}
          />

          <label> Account Number </label>
          <input
            type="number"
            value={acct_num}
            onChange={(e) => {
              setnum(e.target.value);
            }}
            minLength="10"
          />

          <label> Account Name </label>
          <input
            type="text"
            value={acct_name}
            onChange={(e) => {
              setname(e.target.value);
            }}
          />
        </div>
        <p className="text-green-400 text-18 font-bold ml-7 mt-2">
          {updateState}
        </p>
        <button className="btn-1" onClick={update}>
          Update Details
        </button>
        <button
          className="btn-2"
          onClick={() => {
            props.show("");
          }}
        >
          Cancel
        </button>
      </form>
    </section>
  );
};

export default BankEdit;
