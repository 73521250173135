import { useState, useEffect } from "react";
import axios from "axios";

const useFetch = (id) => {
  const [User, setUser] = useState([]);
  const [Referral, setReferral] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_USER_URL}${id}`)
      .then(({ data }) => {
        setUser(data);
        axios
          .get(`${process.env.REACT_APP_REFERRAL_URL}code/${data.unique_code}`)
          .then(({ data }) => setReferral(data))
          .then(() => {
            setLoading(false);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  }, [id]);

  return { User, Referral, Loading };
};

export default useFetch;
