import { Fragment } from "react";
import pexel from "../../dist/pexels 3.png";
import payments from "../../dist/payments.png";
import secure from "../../dist/secure.png";
import analytics from "../../dist/analytics.png";

const ListItem = ({ img, children }) => {
  return (
    <li className="flex items-center text-sm text-tetiary my-4">
      <img className="mr-5 bg-transblue p-1 rounded" src={img} alt="icon" />
      {children}
    </li>
  );
};

const Points = () => {
  return (
    <Fragment>
      <section className="lg:px-28 px-7 py-28 flex flex-col lg:flex-row  items-center justify-between">
        <article className="lg:w-3/5 lg:pr-20">
          <h2 className="font-bold text-lg lg:text-4xl py-2 lg:py-1 pr-14">
            Never Miss out on Affiliate Commissions Again
          </h2>
          <img className="lg:hidden w-56 my-0" src={pexel} alt="third pic" />
          <ul className="pt-3">
            <ListItem img={payments}>
              Earn 100% commission on the first month subscription. This will be
              paid 25% over four months.
            </ListItem>
            <ListItem img={secure}>
              Long term support. We are not going on a short ride. We are here
              to Stay!
            </ListItem>
            <ListItem img={analytics}>
              We are all any device. Bring customers from web, mobile, app. We
              accept them all.
            </ListItem>
          </ul>
        </article>
        <aside className="hidden lg:block lg:w-1/2">
          <img src={pexel} alt="third pic" />
        </aside>
      </section>
    </Fragment>
  );
};

export default Points;
